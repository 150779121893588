/* eslint-disable global-require */

import {theme as baseTheme} from 'native-base';
import Config from './config/config';
import {Project} from './config/config.base';

export const PortBarcelonaTheme = {
  login: {
    backgroundUrl: '/login_background_2.jpg',
    logo: {file: '/logo.gif', width: 250, height: 120},
  },
  images: {
    logoLeft: require('./images/pdb/logo_blanc.png'),
    logoRight1: null,
    logoRight2: null,
    information: require('./images/pdb/Informacion.jpg'),
    materials: require('./images/pdb/Materiales.jpg'),
    production: require('./images/pdb/Produccion.jpg'),
    distribution: require('./images/pdb/Distribucion.jpg'),
  },
  theme: {
    colors: {
      primary: {
        50: '#E8F3F8',
        100: '#CBE3F0',
        200: '#AED3E8',
        300: '#91C3E0',
        400: '#74B3D8',
        500: '#0284C7',
        600: '#0069A3',
        700: '#00517F',
        800: '#00395B',
        900: '#002137',
      },
      secondary: {
        50: '#F7FCFE',
        100: '#E4F4FD',
        200: '#D1ECFC',
        300: '#BEDEFB',
        400: '#A6CDF9',
        500: '#C3E3F6',
        600: '#89B0EF',
        700: '#7592E6',
        800: '#6174DD',
        900: '#4D56D4',
      },
    },
  },
  title: 'Port de Barcelona', // Tab title
  favicon: '/favicon_pb.ico',
};

export const PortwasteTheme = {
  login: {
    backgroundUrl: '/login_background_2.jpg',
    logo: undefined,
  },
  images: {
    logoLeft: require('./images/product-passport-logo.png'),
    logoRight1: null,
    logoRight2: null,
    information: require('./images/pdb/Informacion.jpg'),
    materials: require('./images/pdb/Materiales.jpg'),
    production: require('./images/pdb/Produccion.jpg'),
    distribution: require('./images/pdb/Distribucion.jpg'),
  },
  theme: {
    colors: {
      primary: {
        50: '#E8F3F8',
        100: '#A6CDF9',
        200: '#89B0EF',
        300: '#7592E6',
        400: '#6174DD',
        500: '#1338B5',
        600: '#1338B5',
        700: '#1338B5',
        800: '#5058DD',
        900: '#1338B5',
      },
      secondary: {
        50: '#F7FCFE',
        100: '#E4F4FD',
        200: '#D1ECFC',
        300: '#BEDEFB',
        400: '#A6CDF9',
        500: '#C3E3F6',
        600: '#89B0EF',
        700: '#7592E6',
        800: '#6174DD',
        900: '#0D437F',
      },
    },
  },
  title: 'Portwaste', // Tab title
  favicon: '/images/blueroom.png',
};

export const CelsaAdalmoTheme = {
  login: {
    backgroundUrl: '/login_background.jpg',
    logo: undefined,
  },
  images: {
    logoLeft: require('./images/product-passport-logo.png'),
    logoRight1: require('./images/adalmo-logo.png'),
    logoRight2: require('./images/celsa-logo.png'),
    information: require('./images/adalmo/Information.jpeg'),
    materials: require('./images/adalmo/Materials.jpeg'),
    production: require('./images/adalmo/Production.jpeg'),
    distribution: require('./images/adalmo/Distribution.jpeg'),
  },
  theme: {
    colors: {
      primary: {
        50: baseTheme.colors.indigo[50],
        100: baseTheme.colors.indigo[100],
        200: baseTheme.colors.indigo[200],
        300: baseTheme.colors.indigo[300],
        400: baseTheme.colors.indigo[400],
        500: baseTheme.colors.indigo[500],
        600: baseTheme.colors.indigo[600],
        700: baseTheme.colors.indigo[700],
        800: baseTheme.colors.indigo[800],
        900: baseTheme.colors.indigo[900],
      },
      secondary: {
        500: baseTheme.colors.white,
      },
    },
  },
  title: 'Adalmo Celsa', // Tab title+
  favicon: '/favicon_ac.ico',
};

let theme = null;
if (Config.project === Project.Circularpass) {
  theme = CelsaAdalmoTheme;
} else if (Config.project === Project.PortDeBarcelona) {
  theme = PortBarcelonaTheme;
} else if (Config.project === Project.Portwaste) {
  theme = PortwasteTheme;
} else {
  // Fallback
  theme = CelsaAdalmoTheme;
}

export const CurrentTheme = theme;
