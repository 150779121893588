import {FC} from 'react';
import {HStack, Image, Link, Pressable, View, Text, Stack} from 'native-base';
import {useTranslation} from 'react-i18next';
import {GenericBox, Props as GenericBoxProps} from './GenericBox';
import logo from '../images/circulartrust_logo.png';

export type Props = GenericBoxProps & {
  imageUrl?: string;
  contract?: string;
  productGtin?: string;
};

const CircularTrustDid: FC<Props> = props => {
  const {t} = useTranslation();

  const hasAllData = props.contract && props.productGtin;
  const defaultTitle = 'CircularTrust DID';
  let link = '';

  if (hasAllData) link = `did:nft:eip255:197_erc998:${props.contract}:${props.productGtin}`;

  return (
    <GenericBox {...props} title={props.title ?? defaultTitle}>
      <HStack>
        <Stack direction="row" justifyItems="center" alignItems="center">
          <View flex={8 / 10}>
            <Pressable>
              <HStack space="sm">
                <Text color="primary.600" fontWeight="bold" alignSelf="center" w="100%">
                  {hasAllData ? `did:nft:eip255:197_erc998:${props.contract}:${props.productGtin}` : t(`No se ha encontrado ${props.title ?? defaultTitle}`)!}
                </Text>
              </HStack>
            </Pressable>
          </View>
          <View flex={3 / 10}>
            <Link href={hasAllData ? link : undefined} isExternal>
              <Image src={logo} w="70px" h="70px" />
            </Link>
          </View>
        </Stack>
      </HStack>
    </GenericBox>
  );
};

export default CircularTrustDid;
